import styled from 'styled-components';



export const Wrapper = styled.main`
     width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eef;
    text-align: center;

`

export const BodyContainer = styled.div`
 
    .icon{
        color: #000000;
        font-size: 200px;
       
    }

    .n404{
        color: #000000;
        font-size: 100px;
     
    }

    .pne{
        color: #808080;
        font-size: 20px;
    }
`