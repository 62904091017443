import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
  } from "react-router-dom";
  
  import SignIn from '../pages/SignIn';
  import SignUp from '../pages/SignUp';
  import Dashboard from '../pages/Dashboard';
  import NotFound from '../pages/NotFound'

  
  export const Router = ()=>{
 

    const PrivateRoute = ({ children, redirectTo }) => {
      const isAuthenticated = sessionStorage.getItem("userToken") !== null;
      //console.log("isAuth: ", localStorage.getItem("userToken"));
      return isAuthenticated ? children : <Navigate to={redirectTo} />;
    };
    
    return(
      <BrowserRouter>
      <Routes>
        <Route path="/dashboard" element={<PrivateRoute redirectTo="/"><Dashboard /></PrivateRoute>}/>
        <Route path="/" element={<SignIn />}/>
        <Route path="/signin" element={<SignIn />}/>
        <Route path="/signup" element={<SignUp />}/>
	      <Route path="*" element={<NotFound />}/>
      </Routes>
    </BrowserRouter>
    )
  }


export default Router
  