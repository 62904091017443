import {Wrapper, Background, InputContainer, ButtonContainer} from './styles';
import { useNavigate, Link } from 'react-router-dom';

import background from '../../assets/images/background-login.jpg';
import nowT from '../../assets/images/nowT.bmp';

import Card from '../../components/CardLogin';
import Input from '../../components/Input';
import Button from '../../components/Button';

const SignUp = () => {
    const navigate = useNavigate();

    const handleToSingIn = () => {
        navigate('/dashboard')
    }
    return (
        <Wrapper>
            <Background image={background}/>
            <Card width="403px" height="auto">
                <img src={nowT} width={300} height={100} alt="now" />

                <InputContainer>
                    <Input placeholder="Digite aqui seu usuário"/>
                    <Input placeholder="Digite aqui seu E-mail"/>
                </InputContainer>

                <ButtonContainer>
                    <Button type="button" onClick={handleToSingIn}>Recuperar senha</Button>
                    <p><Link to="/signin">Voltar para o login</Link></p>
                </ButtonContainer>
            </Card>
        </Wrapper>
    )
}

export default SignUp;
