import React from 'react'
import {Wrapper, BodyContainer} from './styles';

const NotFound = () => {
  
    return (
        <Wrapper>
            <BodyContainer>      
                <i className="fa fa-frown-o icon"></i>
                <h1 className="n404">404</h1>
                <h1 className='pne'>página não encontrada</h1>
            </BodyContainer>
        </Wrapper>
    )
}

export default NotFound
