import styled from 'styled-components';


export const HeaderContainer = styled.head`
    width: 100%;
    height: 70px;

    background-color: ${({theme}) => theme.colors.background};
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const HeaderWrapper = styled.div`
    width: 80%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
`

export const UserInfo = styled.div`
  margin-left: 20px;
  display: flex;
  justify-content: right;
  flex-grow: 1;


  .font-bold{
    overflow-wrap: break-word;
  }
  
  @media(max-width:400px) {

    .font-bold{
        float:right;
         width:100px;
        font-size: 10px;
        margin-left: 2px;
      }
      p{font-size:11px}
      a{font-size:14px}
    }  
  
`